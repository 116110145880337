import { useState, useLayoutEffect, useRef } from "react";
import Image from "next/image";
import Logo from "@/public/assets/logo/logopinatrade.png";
import Trust from "@/public/assets/logo/trust.png";
import styles from "@/components/layout/css/header.module.css";
import useWindowSize from "@/hooks/useWindowSize";
import { triggerSegmentEvent } from "@/lib/segment";

export default function Header({
  home = false,
}) {
  
   const [queryStr, setQueryStr] = useState('')
   const [isSticky, setIsSticky] = useState(false)
   const [open, setOpen] = useState(false)
   const stickyHeader = useRef()

  useLayoutEffect(() => {
        const mainHeader = document.getElementById('main-header');
        const topArea = document.getElementById('top-area');
        // let fixedTop = stickyHeader && stickyHeader.current && stickyHeader.current.offsetTop ? stickyHeader.current.offsetTop : 0
        let fixedTop = stickyHeader.current.offsetTop
        setQueryStr(window ? window.location?.search : '')
        // console.log(fixedTop)
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('sticky-header')
                if(topArea) topArea.classList.add('mt-[-70px]');
                setIsSticky(true);
            } else {
                mainHeader.classList.remove('sticky-header')
                if(topArea) topArea.classList.remove('mt-[-70px]')
                setIsSticky(false);
            }
        }

        window.addEventListener('scroll', fixedHeader)
    }, [])

    const menuClick = ()=>{
      if(open){
        setOpen(false)
      }else{
        setOpen(true)
      }
    }

  return (
    <>
      <header className="absolute bg-white md:bg-transparent 
            max-h-screen z-20 top-0 w-full transition-all duration-700 ease-in-out transform py-4" id="main-header" ref={stickyHeader}>
            <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative">
                    <div className="top-0 left-0 w-full px-0 lg:min-h-0 flex justify-between lg:items-center md:px-0 items-center ">
                      <div className="w-[180px] md:w-[200px] lg:w-[250px]">
                        <a href={"/"} className="w-[100%] h-auto flex items-center">
                         <Image
                        src={Logo}
                        alt="Pina Indonesia"
                        layout="fixed"
                        objectFit="contain"
                        width={170}
                        height={50}
                        priority={true}
                        placeholder="blur"
                        
                      />
                      <span className="ml-4">
                      <Image
                        src={Trust}
                        alt="Pina Indonesia"
                        layout="fixed"
                        objectFit="contain"
                        width={90}
                        height={30}
                        priority={true}
                        placeholder="blur"
                        
                      />
                      </span>
                      </a>
                      </div>
                  <div className="hidden md:flex">
                  
                    {/* <Link passHref href={"/blog"}><span className={`px-10 menu-btn cursor-pointer font-bold ${home ? 'text-white hover:text-[#928849] hover:opacity-70' : 'text-black  hover:text-[#928849] hover:opacity-70'}`}>Blog</span></Link> */}
                    {/* <a className={`px-10 ${isSticky}?'text-[#928849]:'text-white'`}>Blog</a> */}
                    <a  onClick={() => triggerSegmentEvent({ event: 'TR Register Button Web', properties: { source: 'Navigation Bar'}})} href={`https://app.pinatrade.id/register${queryStr}`} className="button border-[#928849] mr-6 hover:border-[#595120] hover:bg-[#595120] border-2 py-2 px-6 md:py-1 lg:py-1 rounded-lg  cursor-pointer transition text-[#928849] hover:text-[#F1FF58] ">
                            <span className="font-medium text-xs md:text-[16px] ">Daftar</span>
                        </a>
                    <a  onClick={() => triggerSegmentEvent({ event: 'TR Sign In Button Web', properties: { source: 'Navigation Bar'}})} href={`https://app.pinatrade.id${queryStr}`} className="button border-2 border-[#928849]  bg-[#928849]  py-2 px-6 md:py-1 lg:py-1 rounded-lg hover:bg-[#595120] hover:border-[#595120] cursor-pointer transition hover:text-[#F1FF58] text-[#F3F4F4]">
                            <span className=" font-medium text-xs md:text-[16px]">Masuk</span>
                        </a>
                    </div>

                    <div className="flex md:hidden group">
                      <a onClick={menuClick} className="space-y-1">
                        <span className="block w-6 h-0.5 bg-[#928849]"></span>
                        <span className="block w-6 h-0.5 bg-[#928849]"></span>
                        <span className="block w-6 h-0.5 bg-[#928849]"></span>
                      </a>
                    </div>
                    
                  </div>
                </div>

                {open &&(
                  <div className="flex w-full px-6 pt-6 columns-2">
                    <a onClick={() => triggerSegmentEvent({ event: 'TR Register Button Web', properties: { source: 'Navigation Bar'}})} href={`https://app.pinatrade.id/register${queryStr}`} className="button grow text-center border-[#928849] mr-6 hover:border-[#928849]  border-2 py-2 px-6 md:py-1 lg:py-2 rounded-xs  cursor-pointer transition">
                            <span className="text-[#928849] font-medium text-xs md:text-md lg:text-lg hover:text-[#928849]">Daftar</span>
                        </a>
                    <a  onClick={() => triggerSegmentEvent({ event: 'TR Sign In Button Web', properties: { source: 'Navigation Bar'}})} href={`https://app.pinatrade.id${queryStr}`} className="button grow text-center border-2 border-[#928849] bg-[#928849] py-2 px-6 md:py-1 lg:py-2 rounded-xs hover:bg-[#928849] hover:border-[#928849] cursor-pointer transition">
                            <span className="text-white font-medium text-xs md:text-md lg:text-lg">Masuk</span>
                        </a>
                  </div>
                )}


            </header>
    </>
  );
}

